import React, { Fragment } from 'react';
import SEO from '../components/seo';
import { ResetCSS } from 'common/src/assets/css/style';
import { loadStripe } from '@stripe/stripe-js';
import Tos from '../containers/Charity/Tos';
import { ThemeProvider } from 'styled-components';
import { charityTheme } from 'common/src/theme/charity';
import {
  CharityWrapper,
  ContentWrapper,
  GlobalStyle,
} from '../containers/Charity/charity.style';
// Learning
// To best leverage Stripe’s advanced fraud functionality,
// include this script on every page, not just the checkout page.
// This allows Stripe to detect anomalous behavior that may be indicative
// of fraud as customers browse your website.
// Note: This is why we are adding it to a Layout component.

const stripePromise = loadStripe(process.env.GATSBY_STRIPE_PK);

const NotFoundPage = () => (
  <ThemeProvider theme={charityTheme}>
    <Fragment>
      <SEO title="Terms of service" />
      <ResetCSS />
      <GlobalStyle />
      <CharityWrapper>
        <ContentWrapper>
          <Tos />
        </ContentWrapper>
      </CharityWrapper>
    </Fragment>
  </ThemeProvider>
);

export default NotFoundPage;
