import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import Icon from 'react-icons-kit';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Image from 'common/src/components/Image';
import Button from 'common/src/components/Button';
import ErrorImage from 'common/src/assets/image/404.svg';
import { home } from 'react-icons-kit/entypo/home';
import { ccw } from 'react-icons-kit/entypo/ccw';
import Container from 'common/src/components/UI/Container';
import { BlockWrapper } from './tos.style';
import TosHtmlContent from './tosHtmlContent';

const Tos = () => {
  return (
    <Box id="tos_page">
      <BlockWrapper>
        <Container>
          <Box>
            <header>
              <Heading as="h5" content="Privacy Policy & Terms of Service" />
            </header>
          </Box>
          <Box>
            <TosHtmlContent />
          </Box>
        </Container>
      </BlockWrapper>
    </Box>
  );
};

export default Tos;
