import styled from 'styled-components';
import { themeGet } from 'styled-system';

export const liStyled = styled.li`
  list-style-type: circle;
  list-style-position: inside;
  color: #6c6791;
`;
export const BlockWrapper = styled.div`
  padding: 3rem 0;

  h3 {
    text-align: center;
  }

  .signupEmailDisplay {
    opacity: 0.6;
    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
      opacity: 1;
    }
  }

  li {
    list-style-type: circle;
    list-style-position: inside;
    color: #6c6791;
  }

  h5 {
    font-size: 1rem;
    line-height: 1.2;
    text-transform: uppercase;
    color: ${themeGet('colors.secondary2', '#4517ff')};
    margin-bottom: 30px;
    @media only screen and (max-width: 1440px) {
      margin-bottom: 27px;
    }
    @media only screen and (max-width: 1360px) {
      margin-bottom: 20px;
    }
    @media only screen and (max-width: 991px) {
      font-size: 13px;
      margin-bottom: 15px;
    }
  }

  .descriptionStyle {
    color: ${themeGet('colors.primary', '#4517ff')};
    font-size: 1.2rem;
    padding: 2rem;
    text-align: center;
  }
`;
